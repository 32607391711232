import { DeviceToImageUrl } from '@canalplus/dive';
import {
  ImageType,
  ImageTypeDimensions,
} from '@canalplus/mycanal-sharedcomponent';
import { useSelector } from 'react-redux';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../helpers/hooks/useIsTvDevice';
import {
  applicationResizeSelector,
  imageLoadingSelector,
  imageQualityPercentageSelector,
} from '../../store/slices/application-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../store/slices/user-selectors';
import styles from './LogoChannel.css';

export type LogoChannelProps = {
  image: DeviceToImageUrl;
  altImage: string;
  dimensions: ImageTypeDimensions;
};

/**
 * Component to display channel logo over media contents
 *
 * @param image       object with default URL (required) and mobile URL (optional)
 * @param altImage    alternative text to logo image
 * @param dimensions  size of image for multi device
 */
function LogoChannel({
  image = { default: '' },
  altImage,
  dimensions,
}: LogoChannelProps): JSX.Element {
  const isTvDevice = useIsTvDevice();
  const resizeMode = useInvariantSelector(applicationResizeSelector);
  const imageLoading = useInvariantSelector(imageLoadingSelector);
  const imageQualityPercentageBySettings = useSelector(
    imageQualityPercentageBySettingsSelector
  );
  const imageQualityPercentage = useInvariantSelector(
    imageQualityPercentageSelector
  );
  const imageQuality =
    imageQualityPercentageBySettings || imageQualityPercentage;

  return (
    <ImageType
      altImage={altImage}
      className={styles.logoChannel__image}
      dimensions={dimensions}
      imageQualityPercentage={imageQuality}
      URLImage={image}
      isTvDevice={isTvDevice}
      resizeMode={resizeMode}
      loading={imageLoading}
    />
  );
}

export default LogoChannel;
