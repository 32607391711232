import { ViewportToImageDimensions } from '@canalplus/dive';
import { DIMENSIONS as GLOBAL_DIMENSIONS } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import { useReducer } from 'react';
import ThumborMediaImage from '../../../../../../components/ThumborMediaImage/ThumborMediaImage';
import { PromotionStateTypes } from '../../../../../../templates/LandingV5/components/PromotionStrate/types';
import styles from './Cover.css';

const DIMENSIONS = {
  promotionBanner: GLOBAL_DIMENSIONS.PROMOTION_BANNER,
  promotionCover: GLOBAL_DIMENSIONS.PROMOTION_COVER,
} as const satisfies Record<PromotionStateTypes, ViewportToImageDimensions>;

export type CoverProps = {
  /**
   * The alt of the img
   */
  alt?: string;
  /**
   * If true, display an animated placeholder during the img loading
   */
  hasLoadingAnimation?: boolean;
  /**
   * The type of the promotion strate: cover or banner
   */
  promotionType: PromotionStateTypes;
  /**
   * The url of the image for mobile
   */
  URLImageCompact?: string;
  /**
   * The url of the image for others devices than mobile
   */
  URLImageRegular: string;
};

function Cover({
  alt,
  hasLoadingAnimation = true,
  promotionType,
  URLImageCompact,
  URLImageRegular,
}: CoverProps): JSX.Element {
  const [isLoading, setIsLoading] = useReducer(
    () => false,
    hasLoadingAnimation
  );

  return (
    <ThumborMediaImage
      url={URLImageRegular}
      urlMobile={URLImageCompact}
      dimensions={DIMENSIONS[promotionType]}
      onLoad={setIsLoading}
      alt={alt}
      className={classNames(styles.cover, {
        [styles['cover--loading']]: isLoading,
      })}
    />
  );
}

export default Cover;
