import { SchemaData, SchemaListItem } from '@canalplus/mycanal-sharedcomponent';
import { Template } from '@canalplus/sdk-hodor';
import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { ApiV2LandingCurrentPage } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/landing';
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import HeadingIdentityV5 from '../../../components/HeadingIdentityV5/HeadingIdentityV5';
import {
  IdentityCover,
  isCoverV5,
} from '../../../components/IdentityCover/IdentityCover';
import { CoverData } from '../../../components/IdentityCover/types';
import { LazyLoaderV2 } from '../../../components/LazyLoaderV2/LazyLoaderV2';
import { useIsFrom } from '../../../components/Page/useIsFrom';
import { SKIP_LINK_ID } from '../../../components/SkipLink/constants';
import TemplateHeaderCanal from '../../../components/TemplateHeader/TemplateHeader';
import {
  StrateMode,
  getLazyLoaderStratesOptions,
} from '../../../constants/strates';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import { getListItemSchemaData } from '../../../helpers/schema/schema-helper';
import I18n from '../../../lang';
import {
  getFeatureTogglePromotionCover,
  hostnameSelector,
  isFirstLevelPageSelector,
} from '../../../store/slices/application-selectors';
import { hasPageCover } from '../../../store/slices/page';
import {
  accessibleEpgIDsSelector,
  authenticatedSelector,
} from '../../../store/slices/user-selectors';
import SearchChannels from '../../../templates/ElectronicProgramGuide/components/SearchChannels/SearchChannels';
import { FocusableFallback } from '../../../templates/FocusableFallback/FocusableFallback';
import PromotionStrate from '../../../templates/LandingV5/components/PromotionStrate/PromotionStrate';
import { PromotionStrateData } from '../../../templates/LandingV5/components/PromotionStrate/data/types';
import getOverlappingClassnameWithCoverV5 from '../../../templates/helpers/IdentityV5Helpers/getOverlappingClassname';
import { StrateV5 } from '../data/formatter';
import styles from './LandingV5.css';
import Strate from './Strate/Strate';

const cx = classNames.bind(styles);

export type LandingV5Props = {
  data: {
    tracking: ApiV2PageTracking;
    currentPage?: ApiV2LandingCurrentPage;
    cover?: CoverData;
    promotionCover?: PromotionStrateData;
    strates?: StrateV5[];
  };
  isSearchEnabled: boolean;
  onFocusable?: () => void;
};

function LandingV5({
  data: { currentPage, cover, promotionCover, strates, tracking },
  isSearchEnabled,
  onFocusable,
}: LandingV5Props): JSX.Element {
  const { t } = useContext(I18n.context);
  const dispatch = useAppDispatch();
  const hostname = useSelector(hostnameSelector);
  const isAuthenticated = useSelector(authenticatedSelector);
  const isFirstLevelPage = useSelector(isFirstLevelPageSelector);
  const isTvDevice = useIsTvDevice();
  const epgIDs = useSelector(accessibleEpgIDsSelector);
  const isFromDetail = useIsFrom(Template.DetailPage);
  const isFromGabaritList = useIsFrom(Template.GabaritList);
  const title = currentPage?.displayName ?? undefined;
  const featureTogglePromotionCover = useInvariantSelector(
    getFeatureTogglePromotionCover
  );
  const hasPromotionalCover = featureTogglePromotionCover && promotionCover;
  const { increment, initialDisplayCount } =
    getLazyLoaderStratesOptions(isTvDevice);

  /**
   * Removes the strate liveTV if the user has no epgIDs.
   */
  const hasEpgIDs = !!epgIDs.length;
  const enabledStrates = useMemo(
    () =>
      strates?.filter(
        (strate) => !(strate.strateMode === StrateMode.LiveTv && !hasEpgIDs)
      ),
    [strates, hasEpgIDs]
  );

  const itemsProps = useMemo(
    () =>
      enabledStrates?.map((strate, index) => {
        const isFirstStrate = index === 0;
        const nextStrate = enabledStrates[index + 1];
        const indexOneBased = index + 1;

        return {
          id: `${SKIP_LINK_ID.strate}-${indexOneBased}`,
          hrefNextStrate: nextStrate
            ? `#${SKIP_LINK_ID.strate}-${indexOneBased + 1}`
            : undefined,
          hasNextStrate: !!nextStrate,
          isFirstStrate,
          isTvDevice,
          key: strate.hash,
          strate,
          tracking,
          ...(isFirstStrate && { onFocusable }),
        };
      }) || [],
    [enabledStrates, isTvDevice, onFocusable, tracking]
  );

  const hasStrates = !!enabledStrates?.length;

  /**
   * Saves the cover in the store to be able to use it for the header transparency.
   */
  useEffect(() => {
    if (cover || hasPromotionalCover) {
      dispatch(hasPageCover(true));
    }
  }, [cover, dispatch, hasPromotionalCover]);

  const schemaData: SchemaData | null = useMemo(
    () =>
      enabledStrates && hasStrates && title
        ? getListItemSchemaData(title, hostname, enabledStrates)
        : null,
    [hasStrates, title, hostname, enabledStrates]
  );

  const overlappingClassname = useMemo(() => {
    const isFirstStrateOverlapping = hasStrates && !!cover?.image;

    if (!isFirstStrateOverlapping) {
      return;
    }

    if (!isCoverV5(cover)) {
      return !isTvDevice ? 'landing--overlapping_deprecated' : undefined;
    }

    return getOverlappingClassnameWithCoverV5(cover);
  }, [hasStrates, cover, isTvDevice]);

  const errorMessage = (
    <div className={cx('landing__error')}>
      <ErrorMessage>{t('ErrorMessages.noContent')}</ErrorMessage>
    </div>
  );

  const showTitle = (!isTvDevice || !isFirstLevelPage) && !isFromDetail;

  return (
    <>
      {hasPromotionalCover && (
        <PromotionStrate
          content={promotionCover.content}
          promotionType={promotionCover.promotionType}
        />
      )}
      {!hasPromotionalCover && <IdentityCover cover={cover} />}
      {isTvDevice && <FocusableFallback onFocusable={onFocusable} />}
      <section
        className={cx('landing', overlappingClassname, {
          'landing--hasTopSpace':
            (!isFromGabaritList || title) &&
            !isCoverV5(cover) &&
            !isFromDetail &&
            !isTvDevice,
        })}
      >
        {isCoverV5(cover) && (
          <div className={cx('landing__heading')} data-heading>
            <HeadingIdentityV5
              cover={cover}
              title={showTitle && title ? title : undefined}
            />
            {isSearchEnabled && <SearchChannels />}
          </div>
        )}
        {!isCoverV5(cover) && title && showTitle && (
          <div className={cx('landing__heading--deprecated')} data-heading>
            <TemplateHeaderCanal title={title} displayBigTitle />
            {isSearchEnabled && <SearchChannels />}
          </div>
        )}
        {hasStrates ? (
          <>
            {schemaData &&
              schemaData.pathnames.length > 0 &&
              !isAuthenticated && <SchemaListItem schemaData={schemaData} />}
            <LazyLoaderV2
              itemsProps={itemsProps}
              component={Strate}
              increment={increment}
              initialDisplayCount={initialDisplayCount}
            />
          </>
        ) : (
          errorMessage
        )}
      </section>
    </>
  );
}

export default LandingV5;
