import { JSXElementConstructor, Key, useCallback, useState } from 'react';
import { useIntersectionObserver } from '../../helpers/hooks/useIntersectionObserver';

export type KeyedItemProps = {
  key: Key;
};

export type LazyLoaderV2Props<ItemProps extends KeyedItemProps> = {
  itemsProps: ItemProps[];
  component: JSXElementConstructor<Omit<ItemProps, 'key'>>;
  initialDisplayCount: number;
  increment: number;
};

export function LazyLoaderV2<ItemProps extends KeyedItemProps>({
  itemsProps,
  component: Component,
  initialDisplayCount,
  increment,
}: LazyLoaderV2Props<ItemProps>): JSX.Element {
  const [count, setCount] = useState(initialDisplayCount);

  const onIntersectEntry = useCallback(
    (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        setCount((value) => value + increment);
      }
    },
    [setCount, increment]
  );

  const { refCallback } = useIntersectionObserver({
    threshold: 0,
    onIntersectEntry,
  });

  return (
    <>
      {itemsProps.slice(0, count).map(({ key, ...rest }) => (
        <Component key={key} {...rest} />
      ))}
      {count < itemsProps.length && (
        <div style={{ height: '1px', width: '1px' }} ref={refCallback} />
      )}
    </>
  );
}
