import { ApiV2Strate } from '@dce-front/hodor-types/api/v2/page/dtos/strates/contents/definitions';
import { StrateMode } from '../../constants/strates';

interface IListItemSchemaData {
  title: string;
  hostname: string;
  pathnames: string[];
}

export const getListItemSchemaData = (
  title: string,
  hostname: string,
  strates: ApiV2Strate[]
): IListItemSchemaData => {
  const pathsList = strates.reduce<string[]>((paths, strate) => {
    const isContentRowType = strate.type === 'contentRow';
    const hasContents = !!strate.contents?.length;
    const isLiveTVMode = strate.strateMode === StrateMode.LiveTv;
    // if strate is a non-live content row that has contents
    if (isContentRowType && hasContents && !isLiveTVMode) {
      const newPaths = (strate.contents || []).reduce<string[]>(
        (acc, content) =>
          content?.onClick?.path ? [...acc, content?.onClick?.path] : acc,
        []
      );
      return [...paths, ...newPaths];
    }
    return paths;
  }, []);

  return { title, hostname, pathnames: Array.from(new Set(pathsList)) };
};
