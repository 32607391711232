import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import { setApplicationShortVideoIds } from '../../../../store/slices/application';
import { getShortVideoIdsSelector } from '../../../../store/slices/application-selectors';

/**
 * For some short video, we want to display it only once
 * When a video was fully played, its id is stored in the redux store
 */
export const useShortVideoIds = (): {
  addInShortVideoIds: (newId: string) => void;
  isInShortVideoIds: (id: string) => boolean;
} => {
  const dispatch = useAppDispatch();

  const shortVideoIds = useSelector(getShortVideoIdsSelector);

  const addInShortVideoIds = (newId: string) => {
    if (!shortVideoIds.includes(newId)) {
      dispatch(setApplicationShortVideoIds([...shortVideoIds, newId]));
    }
  };

  const isInShortVideoIds = (id: string) => {
    return shortVideoIds.includes(id);
  };

  return {
    addInShortVideoIds,
    isInShortVideoIds,
  };
};
